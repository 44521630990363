import React from "react";

const Products = ({ products }) => {
  return (
    products.map((product, index) => {
      return (<React.Fragment key={`${index}_${product}`}>
        <h2>
          {product.name}
        </h2>
        <img src={product.image.url} alt={product.name} style={{width: window.innerWidth}}></img>
        </React.Fragment>
      )
    })
  )
};

export default Products;