import { useEffect, useState } from 'react';
import { commerce } from './lib/commerce';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Products from './components/Products';

function App() {
  const [products, setProducts] = useState([]);
  const fetchProducts = async () => {
    const response = await commerce.products.list();
    setProducts((response && response.data) || []);
  };
  useEffect(() => { fetchProducts(); }, []);
  console.log({ products });
  return (
    <Router>
      <Routes>
        <Route exact path='/' element={<Products products={products} />}>
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
